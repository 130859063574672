@import url(~@fortawesome/fontawesome-free/css/all.css);
@font-face {
  font-family: "RobotoLight";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("/fonts/RobotoLight/RobotoLight.eot");
  src: url("/fonts/RobotoLight/RobotoLight.eot?#iefix") format("embedded-opentype"), url("/fonts/RobotoLight/RobotoLight.woff") format("woff"), url("/fonts/RobotoLight/RobotoLight.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoBold";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("/fonts/RobotoBold/RobotoBold.eot");
  src: url("/fonts/RobotoBold/RobotoBold.eot?#iefix") format("embedded-opentype"), url("/fonts/RobotoBold/RobotoBold.woff") format("woff"), url("/fonts/RobotoBold/RobotoBold.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoBlack";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("/fonts/RobotoBlack/RobotoBlack.eot");
  src: url("/fonts/RobotoBlack/RobotoBlack.eot?#iefix") format("embedded-opentype"), url("/fonts/RobotoBlack/RobotoBlack.woff") format("woff"), url("/fonts/RobotoBlack/RobotoBlack.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("/fonts/RobotoRegular/Roboto.eot");
  src: url("/fonts/RobotoRegular/Roboto.eot?#iefix") format("embedded-opentype"), url("/fonts/RobotoRegular/Roboto.woff") format("woff"), url("/fonts/RobotoRegular/Roboto.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoThin";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("/fonts/RobotoThin/RobotoThin.eot");
  src: url("/fonts/RobotoThin/RobotoThin.eot?#iefix") format("embedded-opentype"), url("/fonts/RobotoThin/RobotoThin.woff") format("woff"), url("/fonts/RobotoThin/RobotoThin.ttf") format("truetype");
}

.inline-block {
  display: inline-block;
}

.w-auto {
  width: auto !important;
}

.w-100 {
  width: 100% !important;
}

.w-90 {
  width: 90% !important;
}

.w-80 {
  width: 80% !important;
}

.w-70 {
  width: 70% !important;
}

.w-60 {
  width: 60% !important;
}

.w-50 {
  width: 50% !important;
}

.w-40 {
  width: 40% !important;
}

.w-30 {
  width: 30% !important;
}

.w-20 {
  width: 20% !important;
}

.w-10 {
  width: 10% !important;
}

.h-auto {
  height: auto !important;
}

.h-100 {
  height: 100% !important;
}

.h-90 {
  height: 90% !important;
}

.h-80 {
  height: 80% !important;
}

.h-70 {
  height: 70% !important;
}

.h-60 {
  height: 60% !important;
}

.h-50 {
  height: 50% !important;
}

.h-40 {
  height: 40% !important;
}

.h-30 {
  height: 30% !important;
}

.h-20 {
  height: 20% !important;
}

.h-10 {
  height: 10% !important;
}

.p-4 {
  padding: 4em !important;
}

.p-3 {
  padding: 3em !important;
}

.p-2 {
  padding: 2em !important;
}

.p-1 {
  padding: 1em !important;
}

.p-0 {
  padding: 0 !important;
}

@media (max-width: 768px) {
  .p-sm-0 {
    padding: 0 !important;
  }
}

@media (max-width: 768px) {
  .p-sm-1 {
    padding: 1em !important;
  }
}

@media (max-width: 768px) {
  .p-sm-2 {
    padding: 2em !important;
  }
}

@media (max-width: 768px) {
  .p-sm-3 {
    padding: 3em !important;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .p-md-0 {
    padding: 0 !important;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .p-md-1 {
    padding: 1em !important;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .p-md-2 {
    padding: 2em !important;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .p-md-3 {
    padding: 3em !important;
  }
}

.p-lr-1 {
  padding-left: 1em;
  padding-right: 1em;
}

.p-lr-15 {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 30px;
}

.p-tb-1 {
  padding-top: 1em;
  padding-bottom: 1em;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: 1em;
  padding-right: 1em;
}

.px-2 {
  padding-left: 2em;
  padding-right: 2em;
}

.px-3 {
  padding-left: 3em;
  padding-right: 3em;
}

.px-4 {
  padding-left: 4em;
  padding-right: 4em;
}

.px-5 {
  padding-left: 5em;
  padding-right: 5em;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: 1em;
  padding-bottom: 1em;
}

.py-2 {
  padding-top: 2em;
  padding-bottom: 2em;
}

.py-3 {
  padding-top: 3em;
  padding-bottom: 3em;
}

.py-4 {
  padding-top: 4em;
  padding-bottom: 4em;
}

.py-5 {
  padding-top: 5em;
  padding-bottom: 5em;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 1em !important;
}

.pl-2 {
  padding-left: 2em !important;
}

.pl-3 {
  padding-left: 3em !important;
}

.pl-4 {
  padding-left: 4em !important;
}

.pl-5 {
  padding-left: 5em !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 1em !important;
}

.pr-2 {
  padding-right: 2em !important;
}

.pr-3 {
  padding-right: 3em !important;
}

.pr-4 {
  padding-right: 4em !important;
}

.pr-5 {
  padding-right: 5em !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1, .pv-1 {
  padding-top: 1em !important;
}

.pt-2, .pv-2 {
  padding-top: 2em !important;
}

.pt-3, .pv-3 {
  padding-top: 3em !important;
}

.pt-4, .pv-4 {
  padding-top: 4em !important;
}

.pt-5, .pv-5 {
  padding-top: 5em !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1, .pv-1 {
  padding-bottom: 1em !important;
}

.pb-2, .pv-2 {
  padding-bottom: 2em !important;
}

.pb-3, .pv-3 {
  padding-bottom: 3em !important;
}

.pb-4, .pv-4 {
  padding-bottom: 4em !important;
}

.pb-5, .pv-5 {
  padding-bottom: 5em !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.m-auto {
  margin: 0 auto !important;
}

.mr-1, .mx-1, .mh-1 {
  margin-right: 1em !important;
}

.mr-2, .mx-2, .mh-2 {
  margin-right: 2em !important;
}

.mr-3, .mx-3, .mh-3 {
  margin-right: 3em !important;
}

.mr-4, .mx-4, .mh-4 {
  margin-right: 4em !important;
}

.mr-5, .mx-5, .mh-5 {
  margin-right: 5em !important;
}

.ml-1, .mx-1, .mh-1 {
  margin-left: 1em !important;
}

.ml-2, .mx-2, .mh-2 {
  margin-left: 2em !important;
}

.ml-3, .mx-3, .mh-3 {
  margin-left: 3em !important;
}

.ml-4, .mx-4, .mh-4 {
  margin-left: 4em !important;
}

.ml-5, .mx-5, .mh-5 {
  margin-left: 5em !important;
}

.mt-1, .my-1, .mv-1 {
  margin-top: 1em !important;
}

.mt-2, .my-2, .mv-2 {
  margin-top: 2em !important;
}

.mt-3, .my-3, .mv-3 {
  margin-top: 3em !important;
}

.mt-4, .my-4, .mv-4 {
  margin-top: 4em !important;
}

.mt-5, .my-5, .mv-5 {
  margin-top: 5em !important;
}

.mb-1, .my-1, .mv-1 {
  margin-bottom: 1em !important;
}

.mb-2, .my-2, .mv-2 {
  margin-bottom: 2em !important;
}

.mb-3, .my-3, .mv-3 {
  margin-bottom: 3em !important;
}

.mb-4, .my-4, .mv-4 {
  margin-bottom: 4em !important;
}

.mb-5, .my-5, .mv-5 {
  margin-bottom: 5em !important;
}

.br-0 {
  border-radius: 0 !important;
}

.lh-2 {
  line-height: 2;
}

.bdt-1 {
  border-top: 1px solid #ddd;
}

.bdb-1 {
  border-bottom: 1px solid #ddd;
}

.text-primary {
  color: #1422aa !important;
}

.text-warning {
  color: #f9b233 !important;
}

.text-danger {
  color: #e4201b !important;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000000 !important;
}

.text-info {
  color: #a3d3e5 !important;
}

.text-success {
  color: #3fa435 !important;
}

.text-gray {
  color: #CCCCCC !important;
}

.text-red {
  color: #FF0000 !important;
}

.panel-warning {
  border-color: #f9b233 !important;
}

.bg-light {
  background-color: #f2f2f2 !important;
}

.bg-dark {
  background-color: #666 !important;
}

.bg-cover {
  background-position: center;
  background-size: cover;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-primary {
  background-color: #1422aa !important;
  color: #FFFFFF !important;
}

.bg-gray {
  background-color: #CCCCCC !important;
}

.bg-orange {
  background-color: #f18425 !important;
}

.bg-red {
  background-color: #FF0000 !important;
}

.flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
  overflow-x: auto;
}

.flex-row {
  display: flex;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: row !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.box-shadow {
  box-shadow: 0 3px 15px rgba(20, 34, 170, 0.4);
}

.shadow {
  -webkit-box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.1);
}

.shadow-2 {
  -webkit-box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.2);
}

.shadow-2:hover {
  -webkit-box-shadow: 1px 2px 12px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 1px 2px 12px 0 rgba(0, 0, 0, 0.3);
}

.border-1 {
  border: 1px solid #cccccc;
}

.no-shadow {
  box-shadow: none;
}

.title {
  font-size: 36px;
  font-weight: 100;
  margin-bottom: 55px;
  font-family: RobotoThin, sans-serif;
}

.no-border {
  border: none;
}

tr.no-border td {
  border-top: none !important;
}

.underline {
  border-bottom: 1px;
  border-bottom-style: solid;
  border-bottom-color: #1422aa;
}

.underline-dashed {
  border-bottom: 1px;
  border-bottom-style: dashed;
  border-bottom-color: #1422aa;
}

.fw-900 {
  font-weight: 900 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fz-40 {
  font-size: 3.4em !important;
}

.fz-38 {
  font-size: 3.2em !important;
}

.fz-36 {
  font-size: 3em !important;
}

.fz-34 {
  font-size: 2.8em !important;
}

.fz-32 {
  font-size: 2.6em !important;
}

.fz-30 {
  font-size: 2.4em !important;
}

.fz-28 {
  font-size: 2.2em !important;
}

.fz-26 {
  font-size: 2em !important;
}

.fz-24 {
  font-size: 1.8em !important;
}

.fz-22 {
  font-size: 1.6em !important;
}

.fz-20 {
  font-size: 1.4em !important;
}

.fz-18 {
  font-size: 1.2em !important;
}

.fz-16 {
  font-size: 1em !important;
}

.fz-15 {
  font-size: 0.9em !important;
}

.fz-14 {
  font-size: 0.8em !important;
}

.fz-13 {
  font-size: 0.7em !important;
}

.fz-12 {
  font-size: 0.6em !important;
}

.fs-i {
  font-style: italic;
}

.ff-light {
  font-family: Roboto, sans-serif;
}

.ff-bold {
  font-family: RobotoBold, sans-serif;
}

.ff-black {
  font-family: RobotoBlack, sans-serif;
}

.va {
  vertical-align: middle;
}

.toggle-span-on-collapse span.show-on-collapse {
  display: none;
}

.toggle-span-on-collapse span.hide-on-collapse {
  display: inline;
}

.toggle-span-on-collapse.collapsed span.show-on-collapse {
  display: inline;
}

.toggle-span-on-collapse.collapsed span.hide-on-collapse {
  display: none;
}

.has-error .checkbox, .has-error .checkbox-inline,
.has-error .control-label, .has-error .help-block,
.has-error .radio, .has-error .radio-inline,
.has-error.checkbox label, .has-error.checkbox-inline label,
.has-error.radio label, .has-error.radio-inline label {
  color: #e4201b;
}

.alert-danger {
  color: #e4201b;
}

.has-error .form-control {
  border-color: #e4201b;
}

.no-gutters {
  margin-right: -15px;
  margin-left: -15px;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.d-block {
  display: block;
}

.border-n {
  border: none !important;
}

.border-b {
  border-bottom: 1px solid #ddd !important;
}

.border-t {
  border-top: 1px solid #ddd !important;
}

.border-l {
  border-left: 1px solid #ddd !important;
}

.border-r {
  border-right: 1px solid #ddd !important;
}

.abs {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.abs.spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.abs.spinner span {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 1em;
}

.abs.spinner span i {
  margin: 15px;
}

.abs.spinner.overlay {
  background-color: rgba(200, 200, 200, 0.5);
  z-index: 100;
}

.float-right {
  float: right;
}

.text-shadow {
  text-shadow: 2px 2px 4px #999999;
}

.text-shadow-black {
  text-shadow: 2px 2px 4px #000000;
}

.text-shadow-white {
  text-shadow: 2px 2px 4px #000000;
}

.text-bold {
  font-weight: bold;
}

.text-spacing {
  letter-spacing: 0.4em;
}

@media (max-width: 768px) {
  .text-sm-center {
    text-align: center;
  }
}

.animation-shaking {
  animation: shake 0.82s infinite ease-in-out;
  transform: translate3d(2px, 0, 0);
}

@keyframes shake {
  from {
    transform: translate3d(2px, 0, 0);
  }
  50% {
    transform: translate3d(-2px, 0, 0);
  }
  to {
    transform: translate3d(2px, 0, 0);
  }
}

.bg-warning2 {
  background-color: #f9b233;
}

.cursor-help {
  cursor: help;
}

.parent-hovershow .hovershow-inline {
  display: none;
}

.parent-hovershow .hovershow-block {
  display: none;
}

.parent-hovershow:hover .hovershow-inline {
  display: inline-block;
}

.parent-hovershow:hover .hovershow-block {
  display: block;
}

.underline-onhover:hover {
  text-decoration: underline !important;
}

.inline-middle-block {
  display: inline-block;
  vertical-align: bottom;
}

body {
  color: #777;
  line-height: 1.65;
  font-size: 16px;
  font-family: Roboto, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
  color: #1422aa;
}

a {
  color: #1422aa;
}

ul li {
  list-style: none;
  padding: 10px 0;
  position: relative;
}

ul li::before {
  content: '\f00c';
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  position: absolute;
  left: -25px;
  color: #fecf73;
}

.block-border {
  border: 2px solid #fecf73;
  margin-left: -30px;
  margin-right: -30px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 30px;
}

.block-border ul li::before {
  color: #fecf73;
}

.block-border-2 {
  border: 2px solid #fecf73;
  padding: 25px 15px 15px 15px;
  margin-left: -30px;
  margin-right: -30px;
}

.bg-color {
  background: rgba(254, 207, 115, 0.3);
}

a.read-more {
  color: #a3d3e5;
}

.bg-gray {
  background-color: #f6f6f6;
}

.bg-white {
  background-color: #ffffff;
}

.s-header {
  background-image: url("/img/cooperation/bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  min-height: 600px;
  padding-top: 30px;
}

.s-header .logo-wrap {
  color: #000000;
}

.s-header .logo-wrap .logo {
  width: 100px;
  height: auto;
}

.s-header .logo-wrap h4 {
  color: #000000;
}

.s-header .logo-wrap p {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 5px;
  padding-bottom: 20px;
}

.main-wrap {
  transform: translateY(-300px);
  background-color: #ffffff;
  padding: 30px;
}

.accordion-advantages .accordion-card {
  padding: 30px 0;
}

.accordion-advantages .accordion-card-head a {
  color: #fecf73;
  display: block;
  width: 100%;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  padding-right: 140px;
  padding-left: 40px;
}

.accordion-advantages .accordion-card-head a i:first-child {
  margin-right: 10px;
  font-size: 30px;
  position: absolute;
  top: -3px;
  left: 0;
}

.accordion-advantages .accordion-card-head a i:last-child {
  margin-left: 15px;
  position: absolute;
  top: 10%;
  right: 110px;
  transform: rotate(0deg);
  transition: all 0.3s ease;
}

.accordion-advantages .accordion-card-head a.collapsed i:last-child {
  position: absolute;
  top: 10%;
  right: 110px;
  transform: rotate(180deg);
}

.accordion-advantages .accordion-card-body thead {
  white-space: nowrap;
  background-color: #fecf73;
  color: #ffffff;
}

.accordion-advantages .accordion-card-body thead tr th {
  border: none;
  padding: 25px 15px;
}

.accordion-advantages .accordion-card-body tbody tr td {
  border-top: none;
  border-bottom: 2px solid #fecf73;
  padding: 25px 15px;
}

.accordion-advantages .accordion-card-body .table-2 thead {
  background-color: #fecf73;
  color: #ffffff;
}

.accordion-advantages .accordion-card-body .table-2 thead tr th {
  border: none;
  padding: 25px 15px;
  vertical-align: top;
}

.accordion-advantages .accordion-card-body .table-2 tbody tr td {
  border-top: none;
  border-bottom: 2px solid #fecf73;
  padding: 25px 15px;
}

.partners-wrap a {
  text-decoration: none;
  display: block;
  width: 100%;
  padding-right: 40px;
}

.partners-wrap a[aria-expanded="true"] i {
  margin-left: 15px;
  position: absolute;
  right: 110px;
  transition: all 0.3s ease;
  transform: rotate(-180deg);
}

.partners-wrap a[aria-expanded="false"] i {
  transform: rotate(0deg);
  transition: all 0.3s ease;
  position: absolute;
  right: 110px;
}

.partners-wrap .partners-flex-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.partners-wrap .partners-flex-wrap .partner {
  width: 195px;
  height: 250px;
  padding: 20px;
  margin-bottom: 45px;
  font-size: 14px;
  position: relative;
}

.partners-wrap .partners-flex-wrap .partner span {
  font-weight: 700;
}

.partners-wrap .partners-flex-wrap .item-1 {
  border: 2px solid #1422aa;
}

.partners-wrap .partners-flex-wrap .item-1 span {
  color: #1422aa;
}

.partners-wrap .partners-flex-wrap .item-1::after {
  position: absolute;
  display: block;
  top: 50%;
  right: -35px;
  content: '\f30b';
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  transform: translateY(-50%);
  font-size: 26px;
  color: #1422aa;
}

.partners-wrap .partners-flex-wrap .item-2 {
  border: 2px solid #1422aa;
}

.partners-wrap .partners-flex-wrap .item-2 span {
  color: #1422aa;
}

.partners-wrap .partners-flex-wrap .item-2::after {
  position: absolute;
  display: block;
  top: 50%;
  right: -35px;
  content: '\f30b';
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  transform: translateY(-50%);
  font-size: 26px;
  color: #1422aa;
}

.partners-wrap .partners-flex-wrap .item-3 {
  border: 2px solid #fecf73;
}

.partners-wrap .partners-flex-wrap .item-3 span {
  color: #fecf73;
}

.partners-wrap .partners-flex-wrap .item-3::after {
  position: absolute;
  display: block;
  bottom: -45px;
  left: 50%;
  content: '\f30b';
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  transform: translateX(-50%) rotate(90deg);
  font-size: 26px;
  color: #fecf73;
}

.partners-wrap .partners-flex-wrap .item-4 {
  border: 2px solid #1422aa;
}

.partners-wrap .partners-flex-wrap .item-4 span {
  color: #1422aa;
}

.partners-wrap .partners-flex-wrap .item-4::after {
  position: absolute;
  display: block;
  bottom: -45px;
  left: 50%;
  content: '\f30b';
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  transform: translateX(-50%) rotate(90deg);
  font-size: 26px;
  color: #1422aa;
}

.partners-wrap .partners-flex-wrap .item-5 {
  border: 2px solid #fecf73;
}

.partners-wrap .partners-flex-wrap .item-5 span {
  color: #fecf73;
}

.partners-wrap .partners-flex-wrap .item-5::after {
  position: absolute;
  display: block;
  top: 50%;
  left: -35px;
  content: '\f30b';
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  transform: translateY(-50%) rotate(180deg);
  font-size: 26px;
  color: #fecf73;
}

.partners-wrap .partners-flex-wrap .item-6 {
  border: 2px solid #fecf73;
}

.partners-wrap .partners-flex-wrap .item-6 span {
  color: #fecf73;
}

.partners-wrap .partners-flex-wrap .item-6::after {
  position: absolute;
  display: block;
  top: 50%;
  left: -35px;
  content: '\f30b';
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  transform: translateY(-50%) rotate(180deg);
  font-size: 26px;
  color: #fecf73;
}

.partners-wrap .partners-flex-wrap .item-7 {
  border: 2px solid #1422aa;
}

.partners-wrap .partners-flex-wrap .item-7 span {
  color: #1422aa;
}

.partners-wrap .partners-flex-wrap .item-7::after {
  position: absolute;
  display: block;
  top: 50%;
  right: -35px;
  content: '\f30b';
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  transform: translateY(-50%);
  font-size: 26px;
  color: #1422aa;
}

.partners-wrap .partners-flex-wrap .item-8 {
  border: 2px solid #1422aa;
}

.partners-wrap .partners-flex-wrap .item-8 span {
  color: #1422aa;
}

.partners-wrap .partners-flex-wrap .item-8::after {
  position: absolute;
  display: block;
  top: 50%;
  right: -35px;
  content: '\f30b';
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  transform: translateY(-50%);
  font-size: 26px;
  color: #1422aa;
}

.partners-wrap .partners-flex-wrap .item-9 {
  border: 2px solid #fecf73;
}

.partners-wrap .partners-flex-wrap .item-9 span {
  color: #fecf73;
}

.project-wrap a {
  text-decoration: none;
  display: block;
  width: 100%;
  padding-right: 40px;
}

.project-wrap a[aria-expanded="true"] i {
  margin-left: 15px;
  position: absolute;
  right: 110px;
  transition: all 0.3s ease;
  transform: rotate(-180deg);
}

.project-wrap a[aria-expanded="false"] i {
  transform: rotate(0deg);
  transition: all 0.3s ease;
  position: absolute;
  right: 110px;
}

.project-wrap .project-flex-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.project-wrap .project-flex-wrap .project {
  width: 195px;
  height: 250px;
  padding: 15px;
  margin-bottom: 45px;
  position: relative;
  font-size: 14px;
}

.project-wrap .project-flex-wrap .project span {
  font-weight: 700;
}

.project-wrap .project-flex-wrap .item-1 {
  border: 2px solid #1422aa;
}

.project-wrap .project-flex-wrap .item-1 span {
  color: #1422aa;
}

.project-wrap .project-flex-wrap .item-1::after {
  position: absolute;
  display: block;
  top: 50%;
  right: -35px;
  content: '\f30b';
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  transform: translateY(-50%);
  font-size: 26px;
  color: #1422aa;
}

.project-wrap .project-flex-wrap .item-2 {
  border: 2px solid #1422aa;
}

.project-wrap .project-flex-wrap .item-2 span {
  color: #1422aa;
}

.project-wrap .project-flex-wrap .item-2::after {
  position: absolute;
  display: block;
  top: 50%;
  right: -35px;
  content: '\f30b';
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  transform: translateY(-50%);
  font-size: 26px;
  color: #1422aa;
}

.project-wrap .project-flex-wrap .item-3 {
  border: 2px solid #fecf73;
}

.project-wrap .project-flex-wrap .item-3 span {
  color: #fecf73;
}

.project-wrap .project-flex-wrap .item-3::after {
  position: absolute;
  display: block;
  bottom: -45px;
  left: 50%;
  content: '\f30b';
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  transform: translateX(-50%) rotate(90deg);
  font-size: 26px;
  color: #fecf73;
}

.project-wrap .project-flex-wrap .item-4 {
  border: 2px solid #fecf73;
}

.project-wrap .project-flex-wrap .item-4 span {
  color: #fecf73;
}

.project-wrap .project-flex-wrap .item-5 {
  border: 2px solid #1422aa;
}

.project-wrap .project-flex-wrap .item-5 span {
  color: #1422aa;
}

.project-wrap .project-flex-wrap .item-5::after {
  position: absolute;
  display: block;
  top: 50%;
  left: -35px;
  content: '\f30b';
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  transform: translateY(-50%) rotate(180deg);
  font-size: 26px;
  color: #1422aa;
}

.project-wrap .project-flex-wrap .item-6 {
  border: 2px solid #1422aa;
}

.project-wrap .project-flex-wrap .item-6 span {
  color: #1422aa;
}

.project-wrap .project-flex-wrap .item-6::after {
  position: absolute;
  display: block;
  top: 50%;
  left: -35px;
  content: '\f30b';
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  transform: translateY(-50%) rotate(180deg);
  font-size: 26px;
  color: #1422aa;
}

.questions-wrap .questions-card-header {
  padding-left: 15px;
  padding-right: 40px;
}

.questions-wrap .questions-card-body {
  padding: 0px 20px 20px 30px;
}

.questions-wrap a {
  text-decoration: none;
  display: block;
  width: 100%;
  cursor: pointer;
  padding-bottom: 15px;
  padding-top: 15px;
  transition: all 0.3s ease;
}

.questions-wrap a:hover {
  color: #1422aa !important;
}

.questions-wrap a[aria-expanded="true"] {
  color: #1422aa;
}

.questions-wrap a[aria-expanded="true"] i {
  margin-left: 15px;
  position: absolute;
  right: 40px;
  transition: all 0.3s ease;
  transform: rotate(-180deg);
}

.questions-wrap a[aria-expanded="false"] {
  color: #666666;
}

.questions-wrap a[aria-expanded="false"] i {
  transform: rotate(0deg);
  transition: all 0.3s ease;
  position: absolute;
  right: 40px;
}

.footer {
  padding: 0;
  transform: translateY(180px);
}

.project-link {
  color: #1422aa;
}

.partners-link {
  color: #1422aa;
}

.copyright {
  transform: translateY(170px);
  font-size: 14px;
  color: #999;
}

@media (max-width: 991.98px) {
  .partners-wrap a[aria-expanded="true"] i, .project-wrap a[aria-expanded="true"] i {
    right: 10px;
  }
  .partners-wrap a[aria-expanded="false"] i, .project-wrap a[aria-expanded="false"] i {
    right: 10px;
  }
  .accordion-advantages .accordion-card {
    padding: 30px 0;
  }
  .accordion-advantages .accordion-card-head a {
    padding-right: 30px;
    padding-left: 40px;
  }
  .accordion-advantages .accordion-card-head a i:last-child {
    margin-left: 15px;
    position: absolute;
    top: 0%;
    right: 0px;
    transform: rotate(0deg);
    transition: all 0.3s ease;
  }
  .accordion-advantages .accordion-card-head a.collapsed i:last-child {
    position: absolute;
    top: 0%;
    right: 0px;
    transform: rotate(180deg);
  }
  .partners-wrap .partners-flex-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .partners-wrap .partners-flex-wrap .partner {
    width: 235px;
  }
  .partners-wrap .partners-flex-wrap .item-1 {
    order: 0;
  }
  .partners-wrap .partners-flex-wrap .item-1::after {
    position: absolute;
    display: block;
    top: 50%;
    right: -35px;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(-50%);
    font-size: 26px;
    color: #1422aa;
  }
  .partners-wrap .partners-flex-wrap .item-2 {
    order: 1;
  }
  .partners-wrap .partners-flex-wrap .item-2::after {
    position: absolute;
    display: block;
    top: 100%;
    right: 100px;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: rotate(90deg);
    font-size: 26px;
    color: #1422aa;
  }
  .partners-wrap .partners-flex-wrap .item-3 {
    order: 4;
  }
  .partners-wrap .partners-flex-wrap .item-3::after {
    position: absolute;
    display: block;
    bottom: 42%;
    left: -15%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateX(0) rotate(180deg);
    font-size: 26px;
    color: #fecf73;
  }
  .partners-wrap .partners-flex-wrap .item-4 {
    order: 6;
  }
  .partners-wrap .partners-flex-wrap .item-4::after {
    position: absolute;
    display: block;
    bottom: -45px;
    left: 50%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateX(-50%) rotate(90deg);
    font-size: 26px;
    color: #1422aa;
  }
  .partners-wrap .partners-flex-wrap .item-5 {
    order: 5;
  }
  .partners-wrap .partners-flex-wrap .item-5::after {
    position: absolute;
    display: block;
    top: 42%;
    left: 105%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(0) rotate(0deg);
    font-size: 26px;
    color: #fecf73;
  }
  .partners-wrap .partners-flex-wrap .item-6 {
    order: 3;
  }
  .partners-wrap .partners-flex-wrap .item-6::after {
    position: absolute;
    display: block;
    top: 100%;
    left: 100px;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(0) rotate(90deg);
    font-size: 26px;
    color: #fecf73;
  }
  .partners-wrap .partners-flex-wrap .item-7 {
    order: 8;
  }
  .partners-wrap .partners-flex-wrap .item-7::after {
    position: absolute;
    display: block;
    top: 50%;
    right: 105%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(-50%) rotate(180deg);
    font-size: 26px;
    color: #1422aa;
  }
  .partners-wrap .partners-flex-wrap .item-8 {
    order: 7;
  }
  .partners-wrap .partners-flex-wrap .item-8::after {
    position: absolute;
    display: block;
    top: 100%;
    right: 45%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(0%) rotate(90deg);
    font-size: 26px;
    color: #1422aa;
  }
  .partners-wrap .partners-flex-wrap .item-9 {
    order: 9;
  }
  .partners-wrap .partners-flex-wrap .item-9 span {
    color: #fecf73;
  }
  .project-wrap .project-flex-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .project-wrap .project-flex-wrap .project {
    width: 235px;
    margin-bottom: 52px;
  }
  .project-wrap .project-flex-wrap .item-1 {
    border: 2px solid #1422aa;
    order: 0;
  }
  .project-wrap .project-flex-wrap .item-1 span {
    color: #1422aa;
  }
  .project-wrap .project-flex-wrap .item-1::after {
    position: absolute;
    display: block;
    top: 50%;
    right: -15%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(-50%);
    font-size: 26px;
    color: #1422aa;
  }
  .project-wrap .project-flex-wrap .item-2 {
    border: 2px solid #1422aa;
    order: 0;
  }
  .project-wrap .project-flex-wrap .item-2 span {
    color: #1422aa;
  }
  .project-wrap .project-flex-wrap .item-2::after {
    position: absolute;
    display: block;
    top: 100%;
    right: 42%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: rotate(90deg);
    font-size: 26px;
    color: #1422aa;
  }
  .project-wrap .project-flex-wrap .item-3 {
    border: 2px solid #fecf73;
    order: 2;
  }
  .project-wrap .project-flex-wrap .item-3 span {
    color: #fecf73;
  }
  .project-wrap .project-flex-wrap .item-3::after {
    position: absolute;
    display: block;
    bottom: 42%;
    left: -15%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateX(0) rotate(180deg);
    font-size: 26px;
    color: #fecf73;
  }
  .project-wrap .project-flex-wrap .item-4 {
    border: 2px solid #fecf73;
    order: 3;
  }
  .project-wrap .project-flex-wrap .item-4 span {
    color: #fecf73;
  }
  .project-wrap .project-flex-wrap .item-5 {
    border: 2px solid #1422aa;
    order: 2;
  }
  .project-wrap .project-flex-wrap .item-5 span {
    color: #1422aa;
  }
  .project-wrap .project-flex-wrap .item-5::after {
    position: absolute;
    display: block;
    top: 42%;
    left: 105%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(0) rotate(0deg);
    font-size: 26px;
    color: #1422aa;
  }
  .project-wrap .project-flex-wrap .item-6 {
    border: 2px solid #1422aa;
    order: 1;
  }
  .project-wrap .project-flex-wrap .item-6 span {
    color: #1422aa;
  }
  .project-wrap .project-flex-wrap .item-6::after {
    position: absolute;
    display: block;
    top: 100%;
    left: 42%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(0) rotate(90deg);
    font-size: 26px;
    color: #1422aa;
  }
}

@media (max-width: 767.98px) {
  .partners-wrap a[aria-expanded="true"] i, .project-wrap a[aria-expanded="true"] i {
    right: 10px;
  }
  .partners-wrap a[aria-expanded="false"] i, .project-wrap a[aria-expanded="false"] i {
    right: 10px;
  }
  .accordion-advantages .accordion-card {
    padding: 30px 0;
  }
  .accordion-advantages .accordion-card-head a {
    padding-right: 30px;
    padding-left: 40px;
  }
  .accordion-advantages .accordion-card-head a i:last-child {
    margin-left: 15px;
    position: absolute;
    top: 0%;
    right: 0px;
    transform: rotate(0deg);
    transition: all 0.3s ease;
  }
  .accordion-advantages .accordion-card-head a.collapsed i:last-child {
    position: absolute;
    top: 0%;
    right: 0px;
    transform: rotate(180deg);
  }
  .partners-wrap .partners-flex-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .partners-wrap .partners-flex-wrap .partner {
    width: 205px;
    margin-bottom: 40px;
  }
  .partners-wrap .partners-flex-wrap .item-1 {
    order: 0;
  }
  .partners-wrap .partners-flex-wrap .item-1::after {
    position: absolute;
    display: block;
    top: 50%;
    right: -35px;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(-50%);
    font-size: 26px;
    color: #1422aa;
  }
  .partners-wrap .partners-flex-wrap .item-2 {
    order: 1;
  }
  .partners-wrap .partners-flex-wrap .item-2::after {
    position: absolute;
    display: block;
    top: 100%;
    right: 100px;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: rotate(90deg);
    font-size: 26px;
    color: #1422aa;
  }
  .partners-wrap .partners-flex-wrap .item-3 {
    order: 4;
  }
  .partners-wrap .partners-flex-wrap .item-3::after {
    position: absolute;
    display: block;
    bottom: 42%;
    left: -15%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateX(0) rotate(180deg);
    font-size: 26px;
    color: #fecf73;
  }
  .partners-wrap .partners-flex-wrap .item-4 {
    order: 6;
  }
  .partners-wrap .partners-flex-wrap .item-4::after {
    position: absolute;
    display: block;
    bottom: -45px;
    left: 50%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateX(-50%) rotate(90deg);
    font-size: 26px;
    color: #1422aa;
  }
  .partners-wrap .partners-flex-wrap .item-5 {
    order: 5;
  }
  .partners-wrap .partners-flex-wrap .item-5::after {
    position: absolute;
    display: block;
    top: 42%;
    left: 105%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(0) rotate(0deg);
    font-size: 26px;
    color: #fecf73;
  }
  .partners-wrap .partners-flex-wrap .item-6 {
    order: 3;
  }
  .partners-wrap .partners-flex-wrap .item-6::after {
    position: absolute;
    display: block;
    top: 100%;
    left: 100px;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(0) rotate(90deg);
    font-size: 26px;
    color: #fecf73;
  }
  .partners-wrap .partners-flex-wrap .item-7 {
    order: 8;
  }
  .partners-wrap .partners-flex-wrap .item-7::after {
    position: absolute;
    display: block;
    top: 50%;
    right: 105%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(-50%) rotate(180deg);
    font-size: 26px;
    color: #1422aa;
  }
  .partners-wrap .partners-flex-wrap .item-8 {
    order: 7;
  }
  .partners-wrap .partners-flex-wrap .item-8::after {
    position: absolute;
    display: block;
    top: 100%;
    right: 45%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(0%) rotate(90deg);
    font-size: 26px;
    color: #1422aa;
  }
  .partners-wrap .partners-flex-wrap .item-9 {
    order: 9;
  }
  .partners-wrap .partners-flex-wrap .item-9 span {
    color: #fecf73;
  }
  .project-wrap .project-flex-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .project-wrap .project-flex-wrap .project {
    width: 205px;
    margin-bottom: 40px;
  }
  .project-wrap .project-flex-wrap .project span {
    font-weight: 700;
  }
  .project-wrap .project-flex-wrap .item-1 {
    border: 2px solid #1422aa;
    order: 0;
  }
  .project-wrap .project-flex-wrap .item-1 span {
    color: #1422aa;
  }
  .project-wrap .project-flex-wrap .item-1::after {
    position: absolute;
    display: block;
    top: 50%;
    right: -15%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(-50%);
    font-size: 26px;
    color: #1422aa;
  }
  .project-wrap .project-flex-wrap .item-2 {
    border: 2px solid #1422aa;
    order: 0;
  }
  .project-wrap .project-flex-wrap .item-2 span {
    color: #1422aa;
  }
  .project-wrap .project-flex-wrap .item-2::after {
    position: absolute;
    display: block;
    top: 100%;
    right: 42%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: rotate(90deg);
    font-size: 26px;
    color: #1422aa;
  }
  .project-wrap .project-flex-wrap .item-3 {
    border: 2px solid #fecf73;
    order: 2;
  }
  .project-wrap .project-flex-wrap .item-3 span {
    color: #fecf73;
  }
  .project-wrap .project-flex-wrap .item-3::after {
    position: absolute;
    display: block;
    bottom: 42%;
    left: -15%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateX(0) rotate(180deg);
    font-size: 26px;
    color: #fecf73;
  }
  .project-wrap .project-flex-wrap .item-4 {
    border: 2px solid #fecf73;
    order: 3;
  }
  .project-wrap .project-flex-wrap .item-4 span {
    color: #fecf73;
  }
  .project-wrap .project-flex-wrap .item-5 {
    border: 2px solid #1422aa;
    order: 2;
  }
  .project-wrap .project-flex-wrap .item-5 span {
    color: #1422aa;
  }
  .project-wrap .project-flex-wrap .item-5::after {
    position: absolute;
    display: block;
    top: 42%;
    left: 105%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(0) rotate(0deg);
    font-size: 26px;
    color: #1422aa;
  }
  .project-wrap .project-flex-wrap .item-6 {
    border: 2px solid #1422aa;
    order: 1;
  }
  .project-wrap .project-flex-wrap .item-6 span {
    color: #1422aa;
  }
  .project-wrap .project-flex-wrap .item-6::after {
    position: absolute;
    display: block;
    top: 100%;
    left: 42%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(0) rotate(90deg);
    font-size: 26px;
    color: #1422aa;
  }
  .copyright {
    transform: translateY(200px);
  }
}

@media (max-width: 575.98px) {
  .partners-wrap a[aria-expanded="true"] i, .project-wrap a[aria-expanded="true"] i {
    right: 10px;
  }
  .partners-wrap a[aria-expanded="false"] i, .project-wrap a[aria-expanded="false"] i {
    right: 10px;
  }
  .accordion-advantages .accordion-card {
    padding: 30px 0;
  }
  .accordion-advantages .accordion-card-head a {
    padding-right: 30px;
    padding-left: 40px;
  }
  .accordion-advantages .accordion-card-head a i:last-child {
    margin-left: 15px;
    position: absolute;
    top: 0%;
    right: 0px;
    transform: rotate(0deg);
    transition: all 0.3s ease;
  }
  .accordion-advantages .accordion-card-head a.collapsed i:last-child {
    position: absolute;
    top: 0%;
    right: 0px;
    transform: rotate(180deg);
  }
  .partners-wrap .partners-flex-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .partners-wrap .partners-flex-wrap .partner {
    width: 320px;
    height: 250px;
    padding: 20px;
    margin-bottom: 52px;
    position: relative;
  }
  .partners-wrap .partners-flex-wrap .partner span {
    font-weight: 700;
  }
  .partners-wrap .partners-flex-wrap .item-1 {
    order: 0;
  }
  .partners-wrap .partners-flex-wrap .item-1::after {
    position: absolute;
    display: block;
    top: 100%;
    right: 45%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(0%) rotate(90deg);
    font-size: 26px;
    color: #1422aa;
  }
  .partners-wrap .partners-flex-wrap .item-2 {
    order: 1;
  }
  .partners-wrap .partners-flex-wrap .item-2::after {
    position: absolute;
    display: block;
    top: 100%;
    right: 45%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(0%) rotate(90deg);
    font-size: 26px;
    color: #1422aa;
  }
  .partners-wrap .partners-flex-wrap .item-3 {
    order: 2;
  }
  .partners-wrap .partners-flex-wrap .item-3::after {
    position: absolute;
    display: block;
    bottom: -45px;
    left: 50%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateX(-50%) rotate(90deg);
    font-size: 26px;
    color: #fecf73;
  }
  .partners-wrap .partners-flex-wrap .item-4 {
    order: 6;
  }
  .partners-wrap .partners-flex-wrap .item-4::after {
    position: absolute;
    display: block;
    bottom: -45px;
    left: 50%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateX(-50%) rotate(90deg);
    font-size: 26px;
    color: #1422aa;
  }
  .partners-wrap .partners-flex-wrap .item-5 {
    order: 5;
  }
  .partners-wrap .partners-flex-wrap .item-5::after {
    position: absolute;
    display: block;
    top: 100%;
    left: 45%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(0%) rotate(90deg);
    font-size: 26px;
    color: #fecf73;
  }
  .partners-wrap .partners-flex-wrap .item-6 {
    order: 3;
  }
  .partners-wrap .partners-flex-wrap .item-6::after {
    position: absolute;
    display: block;
    top: 100%;
    left: 45%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(0%) rotate(90deg);
    font-size: 26px;
    color: #fecf73;
  }
  .partners-wrap .partners-flex-wrap .item-7 {
    order: 7;
  }
  .partners-wrap .partners-flex-wrap .item-7::after {
    position: absolute;
    display: block;
    top: 100%;
    right: 45%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(0%) rotate(90deg);
    font-size: 26px;
    color: #1422aa;
  }
  .partners-wrap .partners-flex-wrap .item-8 {
    order: 8;
  }
  .partners-wrap .partners-flex-wrap .item-8::after {
    position: absolute;
    display: block;
    top: 100%;
    right: 45%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(0%) rotate(90deg);
    font-size: 26px;
    color: #1422aa;
  }
  .partners-wrap .partners-flex-wrap .item-9 {
    order: 9;
  }
  .project-wrap .project-flex-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .project-wrap .project-flex-wrap .project {
    width: 320px;
    height: 250px;
    padding: 20px;
    margin-bottom: 40px !important;
    position: relative;
  }
  .project-wrap .project-flex-wrap .project span {
    font-weight: 700;
  }
  .project-wrap .project-flex-wrap .item-1 {
    border: 2px solid #1422aa;
    order: 0;
  }
  .project-wrap .project-flex-wrap .item-1 span {
    color: #1422aa;
  }
  .project-wrap .project-flex-wrap .item-1::after {
    position: absolute;
    display: block;
    top: 100%;
    right: 45%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(0%) rotate(90deg);
    font-size: 26px;
    color: #1422aa;
  }
  .project-wrap .project-flex-wrap .item-2 {
    border: 2px solid #1422aa;
    order: 0;
  }
  .project-wrap .project-flex-wrap .item-2 span {
    color: #1422aa;
  }
  .project-wrap .project-flex-wrap .item-2::after {
    position: absolute;
    display: block;
    top: 100%;
    right: 45%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: rotate(90deg);
    font-size: 26px;
    color: #1422aa;
  }
  .project-wrap .project-flex-wrap .item-3 {
    border: 2px solid #fecf73;
    order: 1;
  }
  .project-wrap .project-flex-wrap .item-3 span {
    color: #fecf73;
  }
  .project-wrap .project-flex-wrap .item-3::after {
    position: absolute;
    display: block;
    bottom: -18%;
    left: 47%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateX(0%) rotate(90deg);
    font-size: 26px;
    color: #fecf73;
  }
  .project-wrap .project-flex-wrap .item-4 {
    border: 2px solid #fecf73;
    order: 3;
  }
  .project-wrap .project-flex-wrap .item-4 span {
    color: #fecf73;
  }
  .project-wrap .project-flex-wrap .item-5 {
    border: 2px solid #1422aa;
    order: 2;
  }
  .project-wrap .project-flex-wrap .item-5 span {
    color: #1422aa;
  }
  .project-wrap .project-flex-wrap .item-5::after {
    position: absolute;
    display: block;
    top: 100%;
    left: 47%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(0%) rotate(90deg);
    font-size: 26px;
    color: #1422aa;
  }
  .project-wrap .project-flex-wrap .item-6 {
    border: 2px solid #1422aa;
    order: 1;
  }
  .project-wrap .project-flex-wrap .item-6 span {
    color: #1422aa;
  }
  .project-wrap .project-flex-wrap .item-6::after {
    position: absolute;
    display: block;
    top: 100%;
    left: 47%;
    content: '\f30b';
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transform: translateY(0) rotate(90deg);
    font-size: 26px;
    color: #1422aa;
  }
}

@media (max-width: 414px) {
  .partners-wrap a[aria-expanded="true"] i, .project-wrap a[aria-expanded="true"] i {
    right: 10px;
  }
  .partners-wrap a[aria-expanded="false"] i, .project-wrap a[aria-expanded="false"] i {
    right: 10px;
  }
  .accordion-advantages .accordion-card {
    padding: 30px 0;
  }
  .accordion-advantages .accordion-card-head a {
    padding-right: 30px;
    padding-left: 40px;
  }
  .accordion-advantages .accordion-card-head a i:last-child {
    margin-left: 15px;
    position: absolute;
    top: 0%;
    right: 0px;
    transform: rotate(0deg);
    transition: all 0.3s ease;
  }
  .accordion-advantages .accordion-card-head a.collapsed i:last-child {
    position: absolute;
    top: 0%;
    right: 0px;
    transform: rotate(180deg);
  }
  .copyright {
    transform: translateY(250px);
  }
}
