@import 'vars';

.inline-block {
  display: inline-block; }

// Ширина в процентах
.w-auto {
  width: auto !important; }

.w-100 {
  width: 100% !important; }

.w-90 {
  width: 90% !important; }

.w-80 {
  width: 80% !important; }

.w-70 {
  width: 70% !important; }

.w-60 {
  width: 60% !important; }

.w-50 {
  width: 50% !important; }

.w-40 {
  width: 40% !important; }

.w-30 {
  width: 30% !important; }

.w-20 {
  width: 20% !important; }

.w-10 {
  width: 10% !important; }

// Высота в процентах
.h-auto {
  height: auto !important; }

.h-100 {
  height: 100% !important; }

.h-90 {
  height: 90% !important; }

.h-80 {
  height: 80% !important; }

.h-70 {
  height: 70% !important; }

.h-60 {
  height: 60% !important; }

.h-50 {
  height: 50% !important; }

.h-40 {
  height: 40% !important; }

.h-30 {
  height: 30% !important; }

.h-20 {
  height: 20% !important; }

.h-10 {
  height: 10% !important; }

// Общий padding
.p-4 {
  padding: 4em !important; }

.p-3 {
  padding: 3em !important; }

.p-2 {
  padding: 2em !important; }

.p-1 {
  padding: 1em !important; }

.p-0 {
  padding: 0 !important; }

.p-sm-0 {
  @media (max-width: 768px) {
    padding: 0 !important; } }

.p-sm-1 {
  @media (max-width: 768px) {
    padding: 1em !important; } }

.p-sm-2 {
  @media (max-width: 768px) {
    padding: 2em !important; } }

.p-sm-3 {
  @media (max-width: 768px) {
    padding: 3em !important; } }

.p-md-0 {
  @media (min-width: 769px) and (max-width: 992px) {
    padding: 0 !important; } }

.p-md-1 {
  @media (min-width: 769px) and (max-width: 992px) {
    padding: 1em !important; } }

.p-md-2 {
  @media (min-width: 769px) and (max-width: 992px) {
    padding: 2em !important; } }

.p-md-3 {
  @media (min-width: 769px) and (max-width: 992px) {
    padding: 3em !important; } }

.p-lr-1 {
  padding-left: 1em;
  padding-right: 1em; }

.p-lr-15 {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 30px; }

.p-tb-1 {
  padding-top: 1em;
  padding-bottom: 1em; }

.px-0 {
  padding-left: 0;
  padding-right: 0; }

.px-1 {
  padding-left: 1em;
  padding-right: 1em; }

.px-2 {
  padding-left: 2em;
  padding-right: 2em; }

.px-3 {
  padding-left: 3em;
  padding-right: 3em; }

.px-4 {
  padding-left: 4em;
  padding-right: 4em; }

.px-5 {
  padding-left: 5em;
  padding-right: 5em; }

.py-0 {
  padding-top: 0;
  padding-bottom: 0; }

.py-1 {
  padding-top: 1em;
  padding-bottom: 1em; }

.py-2 {
  padding-top: 2em;
  padding-bottom: 2em; }

.py-3 {
  padding-top: 3em;
  padding-bottom: 3em; }

.py-4 {
  padding-top: 4em;
  padding-bottom: 4em; }

.py-5 {
  padding-top: 5em;
  padding-bottom: 5em; }
// Левый padding
.pl-0 {
  padding-left: 0 !important; }

.pl-1 {
  padding-left: 1em !important; }

.pl-2 {
  padding-left: 2em !important; }

.pl-3 {
  padding-left: 3em !important; }

.pl-4 {
  padding-left: 4em !important; }

.pl-5 {
  padding-left: 5em !important; }

// Правый padding
.pr-0 {
  padding-right: 0 !important; }

.pr-1 {
  padding-right: 1em !important; }

.pr-2 {
  padding-right: 2em !important; }

.pr-3 {
  padding-right: 3em !important; }

.pr-4 {
  padding-right: 4em !important; }

.pr-5 {
  padding-right: 5em !important; }

// Верхний padding
.pt-0 {
  padding-top: 0 !important; }

.pt-1, .pv-1 {
  padding-top: 1em !important; }

.pt-2, .pv-2 {
  padding-top: 2em !important; }

.pt-3, .pv-3 {
  padding-top: 3em !important; }

.pt-4, .pv-4 {
  padding-top: 4em !important; }

.pt-5, .pv-5 {
  padding-top: 5em !important; }

// Нижний padding
.pb-0 {
  padding-bottom: 0 !important; }

.pb-1, .pv-1 {
  padding-bottom: 1em !important; }

.pb-2, .pv-2 {
  padding-bottom: 2em !important; }

.pb-3, .pv-3 {
  padding-bottom: 3em !important; }

.pb-4, .pv-4 {
  padding-bottom: 4em !important; }

.pb-5, .pv-5 {
  padding-bottom: 5em !important; }


// Общие margin
.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.m-auto {
  margin: 0 auto !important; }

.mr-1, .mx-1, .mh-1 {
  margin-right: 1em !important; }

.mr-2, .mx-2, .mh-2 {
  margin-right: 2em !important; }

.mr-3, .mx-3, .mh-3 {
  margin-right: 3em !important; }

.mr-4, .mx-4, .mh-4 {
  margin-right: 4em !important; }

.mr-5, .mx-5, .mh-5 {
  margin-right: 5em !important; }


.ml-1, .mx-1, .mh-1 {
  margin-left: 1em !important; }

.ml-2, .mx-2, .mh-2 {
  margin-left: 2em !important; }

.ml-3, .mx-3, .mh-3 {
  margin-left: 3em !important; }

.ml-4, .mx-4, .mh-4 {
  margin-left: 4em !important; }

.ml-5, .mx-5, .mh-5 {
  margin-left: 5em !important; }


.mt-1, .my-1, .mv-1 {
  margin-top: 1em !important; }

.mt-2, .my-2, .mv-2 {
  margin-top: 2em !important; }

.mt-3, .my-3, .mv-3 {
  margin-top: 3em !important; }

.mt-4, .my-4, .mv-4 {
  margin-top: 4em !important; }

.mt-5, .my-5, .mv-5 {
  margin-top: 5em !important; }


.mb-1, .my-1, .mv-1 {
  margin-bottom: 1em !important; }

.mb-2, .my-2, .mv-2 {
  margin-bottom: 2em !important; }

.mb-3, .my-3, .mv-3 {
  margin-bottom: 3em !important; }

.mb-4, .my-4, .mv-4 {
  margin-bottom: 4em !important; }

.mb-5, .my-5, .mv-5 {
  margin-bottom: 5em !important; }

.br-0 {
  border-radius: 0 !important; }

.lh-2 {
  line-height: 2; }

.bdt-1 {
  border-top: 1px solid $border; }

.bdb-1 {
  border-bottom: 1px solid $border; }

.text-primary {
  color: $accent !important; }

.text-warning {
  color: $highlight !important; }

.text-danger {
  color: $danger !important; }

.text-white {
  color: #fff !important; }

.text-black {
  color: #000000 !important; }

.text-info {
  color: $active-color !important; }

.text-success {
  color: $green !important; }

.text-gray {
  color: #CCCCCC !important; }

.text-red {
  color: #FF0000 !important; }


.panel-warning {
  border-color: $highlight !important; }

.bg-light {
  background-color: $graybg !important; }

.bg-dark {
  background-color: #666 !important; }

.bg-cover {
  background-position: center;
  background-size: cover; }

.bg-white {
  background-color: #ffffff !important; }

.bg-primary {
  background-color: $accent !important;
  color: #FFFFFF !important; }

.bg-gray {
  background-color: #CCCCCC !important; }

.bg-orange {
  background-color: $orang !important; }

.bg-red {
  background-color: #FF0000 !important; }

.flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
  overflow-x: auto; }

.flex-row {
  display: flex;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: row !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important; }

.box-shadow {
  box-shadow: 0 3px 15px rgba($accent, 0.4); }

.shadow {
  -webkit-box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.1); }

.shadow-2 {
  -webkit-box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.2);

  &:hover {
    -webkit-box-shadow: 1px 2px 12px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 1px 2px 12px 0 rgba(0, 0, 0, 0.3); } }

.border-1 {
  border: 1px solid $lgray; }

.no-shadow {
  box-shadow: none; }

.title {
  font-size: 36px;
  font-weight: 100;
  margin-bottom: 55px;
  font-family: RobotoThin, sans-serif; }

.no-border {
  border: none; }

tr.no-border {
  td {
    border-top: none !important; } }

.underline {
  border-bottom: 1px;
  border-bottom-style: solid;
  border-bottom-color: $accent;

  &-dashed {
    border-bottom: 1px;
    border-bottom-style: dashed;
    border-bottom-color: $accent; } }

.fw-900 {
  font-weight: 900 !important; }

.fw-700 {
  font-weight: 700 !important; }

.fw-500 {
  font-weight: 500 !important; }

// Размеры шрифта
.fz-40 {
  font-size: 3.4em !important; }

.fz-38 {
  font-size: 3.2em !important; }

.fz-36 {
  font-size: 3em !important; }

.fz-34 {
  font-size: 2.8em !important; }

.fz-32 {
  font-size: 2.6em !important; }

.fz-30 {
  font-size: 2.4em !important; }

.fz-28 {
  font-size: 2.2em !important; }

.fz-26 {
  font-size: 2em !important; }

.fz-24 {
  font-size: 1.8em !important; }

.fz-22 {
  font-size: 1.6em !important; }

.fz-20 {
  font-size: 1.4em !important; }

.fz-18 {
  font-size: 1.2em !important; }

.fz-16 {
  font-size: 1em !important; }

.fz-15 {
  font-size: 0.9em !important; }

.fz-14 {
  font-size: 0.8em !important; }

.fz-13 {
  font-size: 0.7em !important; }

.fz-12 {
  font-size: 0.6em !important; }

.fs-i {
  font-style: italic; }

.ff-light {
  font-family: $headline-font; }

.ff-bold {
  font-family: $bold-font; }

.ff-black {
  font-family: $black-font; }

.va {
  vertical-align: middle; }

.toggle-span-on-collapse {
  span {
    &.show-on-collapse {
      display: none; }

    &.hide-on-collapse {
      display: inline; } }

  &.collapsed {
    span {
      &.show-on-collapse {
        display: inline; }

      &.hide-on-collapse {
        display: none; } } } }

.has-error .checkbox, .has-error .checkbox-inline,
.has-error .control-label, .has-error .help-block,
.has-error .radio, .has-error .radio-inline,
.has-error.checkbox label, .has-error.checkbox-inline label,
.has-error.radio label, .has-error.radio-inline label {
  color: $danger; }

.alert-danger {
  color: $danger; }

.has-error .form-control {
  border-color: $danger; }

.no-gutters {
  margin-right: -15px;
  margin-left: -15px; }

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center; }

.d-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch; }

.d-block {
  display: block; }

.border-n {
  border: none !important; }

.border-b {
  border-bottom: 1px solid $border !important; }

.border-t {
  border-top: 1px solid $border !important; }

.border-l {
  border-left: 1px solid $border !important; }

.border-r {
  border-right: 1px solid $border !important; }

.abs {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &.spinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;

    span {
      background-color: rgba(255, 255, 255, 0.5);
      padding: 1em;

      i {
        margin: 15px; } }

    &.overlay {
      background-color: rgba(200, 200, 200, 0.5);
      z-index: 100; } } }

.float-right {
  float: right; }

.text-shadow {
  text-shadow: 2px 2px 4px #999999;

  &-black {
    text-shadow: 2px 2px 4px #000000; }

  &-white {
    text-shadow: 2px 2px 4px #000000; } }

.text-bold {
  font-weight: bold; }

.text-spacing {
  letter-spacing: 0.4em; }

.text-sm-center {
  @media (max-width: 768px) {
    text-align: center; } }

.animation-shaking {
  animation: shake 0.82s infinite ease-in-out;
  transform: translate3d(2px, 0, 0); }

@keyframes shake {
  from {
    transform: translate3d(2px, 0, 0); }

  50% {
    transform: translate3d(-2px, 0, 0); }

  to {
    transform: translate3d(2px, 0, 0); } }

.bg-warning2 {
  background-color: $highlight; }

.cursor-help {
  cursor: help; }

.parent-hovershow {
  .hovershow {
    &-inline {
      display: none; }

    &-block {
      display: none; } }

  &:hover {
    .hovershow {
      &-inline {
        display: inline-block; }

      &-block {
        display: block; } } } }

.underline-onhover {
  &:hover {
    text-decoration: underline !important; } }

.inline-middle-block {
  display: inline-block;
  vertical-align: bottom; }
