@import "mixins/font-face";
@include font-face("RobotoLight", "/fonts/RobotoLight/RobotoLight");
@include font-face("RobotoBold", "/fonts/RobotoBold/RobotoBold");
@include font-face("RobotoBlack", "/fonts/RobotoBlack/RobotoBlack");
@include font-face("Roboto", "/fonts/RobotoRegular/Roboto");
@include font-face("RobotoThin", "/fonts/RobotoThin/RobotoThin");

//+font-face("Font Awesome 5 Free", "../webfonts/fa-regular-400")
//+font-face("Font Awesome 5 Solid", "../webfonts/fa-solid-400")
//+font-face("Font Awesome 5 Brand", "../webfonts/fa-brands-400")
//+font-face("Glyphicons Halflings", "../fonts/glyphicons-halflings-regular")

@mixin ff-roboto() {
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400; }

@mixin ff-roboto-light() {
  font-family: RobotoLight, Arial, sans-serif;
  font-weight: 400; }

@mixin ff-roboto-bold() {
  font-family: RobotoBold, Arial, sans-serif;
  font-weight: 700; }

@mixin ff-arial() {
  font-family: Arial, sans-serif;
  font-weight: 400; }

@mixin ff-arial-bold() {
  font-family: Arial, sans-serif;
  font-weight: 700; }
