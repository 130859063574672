$default-font: Arial, sans-serif;
$headline-font: Roboto, sans-serif;
$bold-font: RobotoBold, sans-serif;
$black-font: RobotoBlack, sans-serif;
$font-awesome: 'Font Awesome 5 Free', sans-serif;
$accent: #1422aa;
$accent_footer: #fff;
$light-blue: #1e33ff;
$highlight: #f9b233;
$blue: #0164a5;
$green: #3fa435;
$light-green: #4cc63f;
$orang: #f18425;
$orange: #fecf73;
$active-color: #a3d3e5;
$info: #f4fdfd;
$textgray: #545454;
$lightgray: #ccc;
$headgray: #333;
$graybg: #f2f2f2;
$gray: #999;
$lgray: rgb(204, 204, 204);
$danger: #e4201b;
$danger-light: rgba(255, 32, 27, 0.84);
$border: #ddd;
$color-white: #fff;
$color-black: #000;

$pad: 15px;

@mixin mt($time) {
  transition: all $time ease; }

@mixin line-vertical($w) {
  content: '';
  position: absolute;
  display: block;
  width: $w;
  height: 100%;
  top: 0;
  left: 0; }

@mixin square($x, $y) {
  content: '';
  position: absolute;
  display: block;
  width: $x;
  height: $y;
  left: 0;
  top: 0; }
